import BaseModel from '~/models/BaseModel'
import { type EsopSchemaDocumentRecipientType } from '~/models/EsopSchema'

export type RecipientStatus =
  | 'WAITING'
  | 'PENDING'
  | 'COMPLETED'
  | 'DECLINED'
  | 'EXPIRED'
  | 'VOIDED'

type RecipientType = {
  type: EsopSchemaDocumentRecipientType
  name: string
  email: string
  status: RecipientStatus
  reason?: string
  sentAt?: string
  signedAt?: string
  declinedAt?: string
}

type StepDetailType = {
  documentName: string
  recipients: RecipientType[]
}

export default class AssetProcessStep extends BaseModel {
  public title!: string
  public subtitle!: string
  public active!: boolean
  public completed!: boolean

  public detail?: StepDetailType[]
}
