import BaseModel from '~/models/BaseModel'
import Participant from '~/models/Participant'
import { type BadgeStatusColors } from '~/components/common/BadgeStatus'
import AssetProcessStep from '~/models/AssetProcessStep'
import { type CustomFieldType } from '~/models/CustomField'
import Document from './Document'

export function getStatusColor(status: string): BadgeStatusColors {
  switch (status) {
    case 'draft':
      return 'outline-grey'
    case 'closed':
      return 'grey'
    case 'pending':
      return 'orange'
    case 'vesting':
    case 'open':
      return 'violet'
    case 'completed':
      return 'blue'
    case 'failed':
      return 'red'
    default:
      return 'default'
  }
}

type EsopPlanData = {
  id: number
  name: string
  assetType?: string
}

export type EsopSchemaData = {
  id: number
  name: string
}

export enum AssetProcessTypes {
  Award = 'AWARD',
  Termination = 'TERMINATION',
  Exercise = 'EXERCISE',
  Buyback = 'BUYBACK',
  DividendPayout = 'DIVIDEND_PAYOUT'
}

export default class AssetProcess extends BaseModel {
  public static baseMapping = {
    participant: () => Participant,
    steps: () => AssetProcessStep
  }

  public static mapping = AssetProcess.baseMapping

  public id!: number

  public displayId!: string

  public participantId!: number

  public participant!: Participant

  public esopPlanId!: number
  public esopPlan!: EsopPlanData

  public esopSchemaId!: number
  public esopSchemaName!: string
  public esopSchema!: EsopSchemaData

  public status!: string
  public stateMachineState!: string

  public steps!: AssetProcessStep[]

  public documents?: Document[]

  public customFields!: CustomFieldType | null

  public get statusColor(): BadgeStatusColors {
    return getStatusColor(this.status)
  }

  public isEditable!: boolean
  public isDeletable!: boolean
  public isArchivable!: boolean
  public isExecuted!: boolean

  public createdAt!: string
}
