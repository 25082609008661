import React from 'react'
import BadgeAssetType from '~/components/common/BadgeAssetType'
import { useFormatters } from '~/hooks/formatters'

type Props = {
  totalAssetCount: number | null
  vestedAssetCount: number | null
  assetType?: string
  badgeClassName?: string
  showOnlyVestedCount?: boolean
}

export function TotalAssetCount(props: Props) {
  const { formatNormalNumber } = useFormatters()

  return props.totalAssetCount ? (
    <>
      <span className="color-grey-500 nowrap">
        <span className="color-black">{formatNormalNumber(props.vestedAssetCount || 0)}</span>
        {!props.showOnlyVestedCount && ' / ' + formatNormalNumber(props.totalAssetCount || 0)}
      </span>
      {props.assetType && (
        <BadgeAssetType assetType={props.assetType} className={props.badgeClassName ?? 'ml-16'} />
      )}
    </>
  ) : (
    <>-</>
  )
}
